import { useContext } from "react";
import { GlobalContext } from "../data/GlobalContext";

import { Link } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";
import { FcHome } from "react-icons/fc";
import {GiLargePaintBrush} from "react-icons/gi";
import {GiPaintBucket} from "react-icons/gi";
import {FcIdea} from 'react-icons/fc';
import {MdOutlineRoofing} from 'react-icons/md';
import { GiGardeningShears } from "react-icons/gi";



const HomeContentOne = () => {
    const dbData = useContext(GlobalContext);
    return (
        <section className="about-two pt-120 pb-120">
            <Container>
                <Row>
                    <Col xl={6}>
                        <div className="about-two__image">
                            <img
                                src={dbData.stock[0] }
                                className='homeContentOne__image'
                                alt="images home" />
                            <div className="about-two__award">
                                <GiGardeningShears color={'white'} />
                            </div>
                        </div>
                    </Col>
                    <Col xl={6}>
                        <div className="about-two__content">
                            <div className="block-title">
                                {
                                    dbData.dbPrincipal.licensed !== ' ' ?
                                        <h3 className="text-capitalize">We are licensed <br /> {dbData.dbPrincipal.licensed}</h3>
                                    :
                                        <h3 className="text-capitalize">We have {dbData.dbPrincipal.exprYears} years of experience</h3>
                                }
                            </div>
                            <p className="mb-40 pr-10">
                                {dbData.dbHome[0].text}
                            </p>
                            <Row>
                                <Col md={6}>
                                    <div className="about-two__box">
                                        {
                                            dbData.dbServices.slice(0, 6).map((service, index) => {
                                                return (
                                                    <Link to='/services' key={index}>
                                                        <h3 className="text-capitalize" >
                                                            <i className="azino-icon-confirmation"></i>
                                                            {service.name}
                                                        </h3>
                                                    </Link>
                                                )
                                            })
                                        }
                                    </div>
                                </Col>
                                <Col md={6}>
                                    <div className="about-two__box-two text-center">
                                        <FcHome fontSize={80}/>
                                        <h3 className="fs-2">
                                            100% <br />
                                            <span className="text-capitalize">
                                                on time
                                            </span>
                                        </h3>
                                    </div>
                                </Col>
                            </Row>
                            <Link className="thm-btn dynamic-radius text-capitalize" to="/contact">
                                free estimate
                            </Link>
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>
    )
}

export default HomeContentOne;