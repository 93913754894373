import { useContext } from "react";
import { GlobalContext } from "../data/GlobalContext";

import { Container, Row, Col } from "react-bootstrap";

import serviceLine from "../../assets/images/shapes/service-line-1-1.png";
import IconTitle from "../IconTitle";

import { GiTreeBranch, GiGardeningShears } from "react-icons/gi";



const ValuesContent = () => {
    const dbData = useContext(GlobalContext);
    return (
        <section
            className="service-one pt-120 pb-90"
        >
            <Container>
                <img src={serviceLine} alt="" className="service-one__shape-1" />
                <div className="block-title">
                    <IconTitle tagLine={`Welcome to ${dbData.dbPrincipal.name}`} />
                </div>
                <Row>
                    <Col md={6} lg={4}>
                        <div className={`service-one__box`}>
                            <div className={`service-one__icon background-base`}>
                                <div className="service-one__icon-inner">
                                    <i class="fa fa-home"></i>
                                </div>
                            </div>
                            <h3>
                                {dbData.dbValues[0].title}
                            </h3>
                            <p className="valuesContent__text"> {dbData.dbValues[0].description} </p>
                        </div>
                    </Col>
                    <Col md={6} lg={4}>
                        <div className={`service-one__box`}>
                            <div className={`service-one__icon background-base`}>
                                <div className="service-one__icon-inner">
                                    <GiGardeningShears fontSize='70' color="White" />
                                </div>
                            </div>
                            <h3>
                                {dbData.dbValues[1].title}
                            </h3>
                            <p className="valuesContent__text"> {dbData.dbValues[1].description} </p>
                        </div>
                    </Col>
                    <Col md={6} lg={4}>
                        <div className={`service-one__box`}>
                            <div className={`service-one__icon background-base`}>
                                <div className="service-one__icon-inner">
                                <GiTreeBranch fontSize='70' color="White" />
                                </div>
                            </div>
                            <h3>
                                {dbData.dbValues[2].title}
                            </h3>
                            <p className="valuesContent__text"> {dbData.dbValues[2].description} </p>
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>
    )
}

export default ValuesContent;