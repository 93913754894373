
import {GiPaintRoller} from "react-icons/gi";
import {MdOutlineRoofing} from "react-icons/md";
import { GiTreeBranch } from "react-icons/gi";

const IconTitle = ({ tagLine }) => {
    return (
        <div className="block-title">
            <p>
                <GiTreeBranch fontSize='50' color="green" />
                <span className="ms-3 text-capitalize">{tagLine}</span>
            </p>
        </div>
    );
};

export default IconTitle;