import { useContext } from "react";
import { GlobalContext } from "../components/data/GlobalContext";

import { Container } from "react-bootstrap";
import { Link } from "react-router-dom";

import Layout from "../components/Layout";
import MainSlider from "../components/slider/MianSlider";
import CalltoActionOne from "../components/call-to-action/CalltoActionOne";
import ValuesContent from "../components/home/ValuesContent";
import CallToActionTwo from "../components/call-to-action/CallToActionTwo";
import HomeContentOne from "../components/home/HomeContentOne";
import DirectorioSlider from "../components/slider/DirectorioSlider";
import RedesContent from "../components/home/RedesContent";
import AboutTwo from "../components/about/AboutTwo";
import IconTitle from "../components/IconTitle";
import CardServicesTwo from "../components/services/CardServicesTwo";
import CallToActionThree from "../components/call-to-action/CallToActionThree";
import GoogleMap from "../components/GoogleMap";
import ModalForm from "../components/modal/ModalForm";
import PaletaColorContent from "../components/PaletaColorContent";

import gmb from '../assets/images/directorios/gmb.png'


const Home = () => {
    const dbData = useContext(GlobalContext);

    return (
        <Layout pageTitle="Home">
            <ModalForm />
            <MainSlider />
            <CalltoActionOne />
            <ValuesContent />
            <CallToActionTwo title={dbData.dbSlogan[2].slogan} bgImages={dbData.stock[5]} />
            <HomeContentOne />
            {
                dbData.gmb.link !== ' ' ?
                    <Container>
                        <div className="d-flex align-items-center justify-content-center">
                            <a
                                href={dbData.gmb.link}
                                target='_blank'
                                rel="noreferrer"
                            >
                                <img src={gmb} width='100%' alt={'GMB images'} />
                            </a>
                        </div>
                    </Container>
                    : null
            }
            {/* <DirectorioSlider extraClass="client-carousel__has-border-top" /> */}
            <h2 className="text-center text-capitalize pb-5">Follow us</h2>
            <RedesContent />
            <AboutTwo images={dbData.stock[7]} />
            <Container className="homeContent__services">
                <div className="block-title">
                    <IconTitle tagLine={`Welcome to ${dbData.dbPrincipal.name}`} />
                    <h3 className="servicesHome__title">our services</h3>
                    {/* <div className="text-center">
                        <Link to='/services' className="text-capitalize">
                            view all services
                        </Link>
                    </div> */}
                </div>
                <CardServicesTwo />
            </Container>
            {/* <PaletaColorContent /> */}
            <CallToActionThree />
            <GoogleMap extraClass="contact" />
        </Layout>
    );
}
export default Home;