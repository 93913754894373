import { useContext } from "react";
import { GlobalContext } from "../../components/data/GlobalContext";

import { Container, Row, Col } from "react-bootstrap";


const imgUrl = require.context('../../assets/images', true);


const CalltoActionOne = () => {
    const dbData = useContext(GlobalContext);
    return (
        <section className="video-card-two">
            <Container>
                <div
                    className="inner-container"
                    style={{ backgroundImage: `url(${imgUrl('./shapes/video-bg-1-1.png')})` }}
                >
                    <Row className="align-items-center">
                        <Col lg={3}>
                            <div className="video-card-two__box">
                                <img src={dbData.stock[4]} width='251px' height='251px' alt="" />
                                <a href={`tel:1${dbData.dbPrincipal.phones[0].phone}`}>
                                    <span
                                        className="video-card-two__box-btn video-popup"
                                        role="button"
                                        tabIndex={0}
                                    >
                                        <i className="fa fa-phone"></i>
                                    </span>
                                </a>
                            </div>
                        </Col>
                        <Col lg={4}>
                            <h3>{dbData.dbSlogan[2].slogan}</h3>
                        </Col>
                        <Col lg={5}>
                            <p>
                                {dbData.dbAbout[1].text.slice(0,180)}
                            </p>
                        </Col>
                    </Row>
                </div>
            </Container>
        </section>
    )
}

export default CalltoActionOne;